import React from "react";
import MapPicture from "./../assets/mapandphones.png";

function Product() {
    return (
        <section class="bg-white " id="product">
            <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
                <div class="font-light text-gray-500 sm:text-lg  my-20">
                    <h2 class="text-4xl tracking-tight font-extrabold text-gray-900  mb-16">Easy to setup, even easier to use</h2>

                    <ol class="items-center sm:flex">
                        <li class="relative mb-6 sm:mb-0">
                            <div class="flex items-center">
                                <div class="z-10 flex items-center justify-center w-6 h-6 bg-primary rounded-full ring-0 ring-white  sm:ring-8 dark:ring-gray-900 shrink-0">
                                    <h2 class="text-white">1</h2>
                                </div>
                                <div class="hidden sm:flex w-full bg-gray-200 h-0.5 "></div>
                            </div>
                            <div class="mt-3 sm:pr-8">
                                <h3 class="text-lg font-semibold text-gray-900 ">Download App</h3>
                                <time class="block mb-2 text-sm font-normal leading-none text-gray-400  my-8">Step 1</time>
                                <p class="text-base font-normal text-gray-500 ">Driver downloads the <em>LogTrack Driver</em> app</p>
                            </div>
                        </li>
                        <li class="relative mb-6 sm:mb-0">
                            <div class="flex items-center">
                                <div class="z-10 flex items-center justify-center w-6 h-6 bg-primary rounded-full ring-0 ring-white  sm:ring-8 dark:ring-gray-900 shrink-0">
                                    <h2 class="text-white">2</h2>
                                </div>
                                <div class="hidden sm:flex w-full bg-gray-200 h-0.5 "></div>
                            </div>
                            <div class="mt-3 sm:pr-8">
                                <h3 class="text-lg font-semibold text-gray-900 ">Share Truck Info</h3>
                                <time class="block mb-2 text-sm font-normal leading-none text-gray-400  my-8">Step 2</time>
                                <p class="text-base font-normal text-gray-500 ">Driver shares tracking ID with the truck dispatcher</p>
                            </div>
                        </li>
                        <li class="relative mb-6 sm:mb-0">
                            <div class="flex items-center">
                                <div class="z-10 flex items-center justify-center w-6 h-6 bg-primary rounded-full ring-0 ring-white  sm:ring-8 dark:ring-gray-900 shrink-0">
                                    <h2 class="text-white">3</h2>
                                </div>
                                <div class="hidden sm:flex w-full bg-gray-200 h-0.5 "></div>
                            </div>
                            <div class="mt-3 sm:pr-8">
                                <h3 class="text-lg font-semibold text-gray-900 ">Track shipments</h3>
                                <time class="block mb-2 text-sm font-normal leading-none text-gray-400  my-8">Step 3</time>
                                <p class="text-base font-normal text-gray-500 ">Track, share and coordinate shipments in real-time!</p>
                            </div>
                        </li>
                    </ol>
                </div>
                <img class="mt-4 w-full lg:mt-10 rounded-lg" src={MapPicture} alt="tracking map" />
            </div>

        </section>
    );
}

export default Product;