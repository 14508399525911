import React, { useRef, useEffect, useState } from "react";
import { InlineWidget } from "react-calendly";
import emailjs from "emailjs-com";
import useAnalyticsEventTracker from "./useAnalyticsEventTracker.jsx";

function Contact() {
  const form = useRef();

  const [show, toggleShow] = useState(false);

  useEffect(() => {
    if (!show) {
      form.current.reset();
    }
  }, [show]);

  const sendEmail = async (e) => {
    e.preventDefault();
    if (form.current.checkValidity()) {
      try {
        await emailjs.sendForm('service_05hmg3r', 'template_xsa48kj', form.current, '7BmUv33lrBXKZi7bS');
        toggleShow(true);
      } catch (error) {
        console.error(error);
      }
    } else {
      form.current.reportValidity();
    }
  };

  const eventTracker = useAnalyticsEventTracker("Contact Us Submit");
  return (
    <section class="bg-white " id="contact">
      <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
        <div>
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900  mt-20">
            Contact Us
          </h2>
          <form ref={form} onSubmit={sendEmail} class="space-y-8">
            <div>
              <label
                for="email"
                class="block mb-2 text-sm font-medium text-primary "
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary0 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-primary0 dark:focus:border-primary0 dark:shadow-sm-light"
                placeholder="johndoe@company.com"
                required
              />
            </div>
            <div>
              <label
                for="subject"
                class="block mb-2 text-sm font-medium text-primary "
              >
                Subject
              </label>
              <input
                type="text"
                id="subject"
                name="subject"
                class="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary focus:border-primary0  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-primary0 dark:focus:border-primary0 dark:shadow-sm-light"
                placeholder="Subject"
                required
              />
            </div>
            <div class="sm:col-span-2">
              <label
                for="message"
                class="block mb-2 text-sm font-medium text-primary "
              >
                Message
              </label>
              <textarea
                id="message"
                name="message"
                rows="6"
                class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary focus:border-primary0  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-primary0 dark:focus:border-primary0"
                placeholder="Let us know how we have done or if we can help you"
              ></textarea>
            </div>

            <div>
              <button
                type="submit"
                onClick={() => { sendEmail(); eventTracker("Contact Us Submit"); }}
                className={`text-white bg-primary hover:bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0  dark:hover:bg-blue-700 dark:focus:ring-blue-800" ${show ? "hidden" : ""
                  }`}
              >
                Send Message
              </button>
              <p
                className={`text-white bg-transparent font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0 " ${!show ? "hidden" : ""
                  }`}
              >
                Your message has been sent! Thank you!
              </p>
            </div>
          </form>
        </div>
        <InlineWidget url="https://calendly.com/logtrack/30min" />
      </div>
    </section>
  );
}

export default Contact;
