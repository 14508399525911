import React, { useEffect } from "react";

//importing components in order of use from top to bottom
import NavBar from "./components/NavBar.jsx";
import Hero from "./components/Hero.jsx";
import About from "./components/About.jsx";
import Product from "./components/Product.jsx";
import Review from "./components/Review.jsx";
import FAQs from "./components/FAQs.jsx";
import Contact from "./components/Contact.jsx";
import Footer from "./components/Footer.jsx";

// javascript library import that controls dropdown menu functionality
import 'flowbite/dist/flowbite.min.js';
import 'flowbite-react';

//importing Google Analytics token
import ReactGA from 'react-ga';

function App() {
  // Google Analytics
  const trackingId = "UA-254960742-1" //This is the tracking ID for the LogTrack landing page
  ReactGA.initialize(trackingId);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div>
      <NavBar />
      <Hero />
      <About />
      <Product />
      <Review />
      <FAQs />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
