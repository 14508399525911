import React from "react";
import DarkLogo from './../assets/lightlogo.png';
import LightLogo from './../assets/darklogo.png';

function Footer() {
    return (
        <footer class="p-4 bg-white md:p-8 lg:p-10 " id="footer">
            <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
            <div class="mx-auto max-w-screen-xl text-center">
                <a href="home" class="flex justify-center items-center text-2xl font-semibold text-gray-900 ">
                    <img
                        src={DarkLogo}
                        class="dark:hidden h-6 mr-3 sm:h-9 block "
                        alt="LogTrack Logo"
                    />
                    <img
                        src={LightLogo}
                        class="dark:block h-6 mr-3 sm:h-9 hidden "
                        alt="LogTrack Logo"
                    />
                </a>
                <p class="my-6 text-gray-500 ">The trucking solution for your business needs</p>
                <ul class="flex flex-wrap justify-center items-center mb-6 text-gray-900 ">
                    <li>
                        <a href="#about" class="mr-4 hover:underline md:mr-6 ">About</a>
                    </li>
                    <li>
                        <a href="#product" class="mr-4 hover:underline md:mr-6">Services</a>
                    </li>
                    <li>
                        <a href="#reviews" class="mr-4 hover:underline md:mr-6 ">Reviews</a>
                    </li>
                    <li>
                        <a href="#faqs" class="mr-4 hover:underline md:mr-6">FAQs</a>
                    </li>
                    <li>
                        <a href="#contact" class="mr-4 hover:underline md:mr-6">Contact</a>
                    </li>
                </ul>
                <span class="text-sm text-gray-500 sm:text-center ">© 2023 <a href="home" class="hover:underline">LogTrack™</a>. All Rights Reserved.</span>
            </div>
        </footer>
    );
}

export default Footer;

