import React from "react";
import HeroVideo from "./../assets/LogTrackHeroVideo.mp4";
import useAnalyticsEventTracker from "./useAnalyticsEventTracker.jsx";

function Hero() {
  const eventTracker = useAnalyticsEventTracker("Login Button on Hero");
  return (
    <section id="home">
      <div className="w-full h-[90vh]">
        <video
          className="w-full h-full object-cover"
          src={HeroVideo}
          playsInline
          autoPlay
          loop
          muted
        />
        <div className="absolute top-0 left-0 w-full h-[90vh] bg-black bg-opacity-30" />
        <div className="absolute top-0 left-0 w-full h-[90vh] items-center justify-center">
          <div class="container px-6 py-8 mx-auto mb-36" />
          <div class="flex max-w-screen-xl px-4 py-20 lg:gap-20 xl:gap-0 lg:py-16 mt-36">
            <div class="mx-auto text-left place-self-center">
              <h1 class="my-6 text-4xl font-extrabold tracking-tight md:text-5xl xl:text-6xl text-white">
                Information Symmetry for the Trucking Industry
              </h1>
              <p class="my-6 font-light lsg:mb-8 md:text-lg lg:text-xl text-white">
                Empowering trucking companies to embrace information efficiency
                and transparency in their operational planning.
              </p>
              <a
                // LINK TO LOGIN PAGE HERE
                onClick={() => { eventTracker("Login Button on Hero") }}
                href="https://app.thelogtrack.com/"
                class="inline-flex items-center justify-center px-5 py-3 mx-4 my-6 text-base font-medium text-center text-white rounded-lg bg-primary hover:bg-secondary focus:ring-4 focus:ring-primary dark:focus:ring-primary"
              >
                Log In
                <svg
                  class="w-5 h-5 ml-2 -mr-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </a>
              <a
                href="#contact"
                class="inline-flex items-center justify-center px-5 py-3 mx-4 text-base font-medium text-center text-white border border-gray-300 rounded-lg hover:bg-secondary focus:ring-4 focus:ring-gray-100  dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
              >
                Work with us
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
